<template lang="pug">
.address-form

  form(@submit.prevent="submit")

    .form-line
        TextInput(
          label="CEP",
          v-model="state.postalCode",
          :mask="maskPostalCode",
          @update:modelValue="checkPostalCode",
          @blur="v$.postalCode.$touch",
          :errors="v$.postalCode.$errors",
          autocomplete="postal-code")
        Loading.postal-code-loading(v-if="postalCodeLoading")

    .form-line
      TextInput(
        label="Endereço",
        v-model.trim="state.street",
        :mask="streetMask",
        @blur="v$.street.$touch",
        :errors="v$.street.$errors",
        :disabled="!postalCodeLoaded",
        style="flex-grow: 4; min-width: 260px")
      TextInput(
        label="Número",
        v-model.trim="state.number",
        :mask="numberMask",
        @blur="v$.number.$touch",
        :errors="v$.number.$errors",
        :disabled="!postalCodeLoaded",
        style="flex-grow: 1",
        autocomplete="off")

    .form-line
      TextInput(
        label="Complemento",
        v-model.trim="state.complement",
        :mask="complementMask",
        @blur="v$.complement.$touch",
        :errors="v$.complement.$errors",
        :disabled="!postalCodeLoaded",
        autocomplete="off")

    .form-line
      TextInput(
        label="Bairro",
        v-model.trim="state.district",
        :mask="districtMask",
        @blur="v$.district.$touch",
        :errors="v$.district.$errors",
        :disabled="!postalCodeLoaded",
        autocomplete="address-level3")

    .form-line
      TextInput(
        label="Cidade",
        v-model.trim="state.city",
        :mask="standardMask",
        @blur="v$.city.$touch",
        :errors="v$.city.$errors",
        :disabled="!postalCodeLoaded",
        style="min-width: 240px"
        autocomplete="address-level2")
      SelectInput(
        label="Estado",
        v-model="state.state",
        @blur="v$.state.$touch",
        :errors="v$.state.$errors",
        :disabled="!postalCodeLoaded",
        style="min-width: 240px",
        autocomplete="address-level1")
        option(value="" label="")
        option(v-for="state in stateOptions", :label="state.label", :value="state.value") {{ state.label }}

    button(v-show="false", type="submit")

</template>
<script setup lang="ts">
import type { PropType } from 'vue'

import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'

import { required, minLength, maxLength } from '~/scripts/validators/validators'
import { maskPostalCode, maxLengthMask } from '~/scripts/masks/masks'

import ViaCEP from '~/services/viaCep'

export interface AddressInfo {
  street: string,
  number: string,
  complement: string,
  district: string,
  city: string,
  state: string,
  postalCode: string
}

const props = defineProps({
  data: {
    type: Object as PropType<AddressInfo>
  },
  onSubmit: {
    type: Function as PropType<() => void>
  }
})

const emit = defineEmits(['update:postalCode'])

const standardMask = maxLengthMask(250)
const numberMask = maxLengthMask(5)
const streetMask = maxLengthMask(50)
const complementMask = maxLengthMask(30)
const districtMask = maxLengthMask(30)

const loading = ref(false)
const postalCodeLoaded = ref(false)
const postalCodeLoading = ref(false)

const state = reactive(props.data ?? {
  street: "",
  complement: "",
  number: "",
  district: "",
  city: "",
  state: "",
  postalCode: ""
});

const rules = {
  street: { required, maxLength: maxLength(250) },
  number: { required, maxLength: maxLength(5) },
  complement: { maxLength: maxLength(30) },
  district: { required, maxLength: maxLength(30) },
  city: { required, maxLength: maxLength(250)},
  state: { required },
  postalCode: {
    required,
    minLenght: helpers.withMessage("Digite um CEP válido", minLength(9))
  },
}

const $externalResults = reactive({
  postalCode: [] as string[]
});

const v$ = useVuelidate(rules, state, { $externalResults })

const submit = () => {
  if (props.onSubmit) {
    props.onSubmit()
  }
}

const checkPostalCode = async (postalCode: string) => {
  if (postalCode.length == 9) {
    try {
      postalCodeLoading.value = true
      postalCodeLoaded.value = false

      state.street = ""
      state.number = ""
      state.complement = ""
      state.district = ""
      state.city = "";
      state.state = "";
      $externalResults.postalCode = [];

      const response = await ViaCEP.findAddressByCep(postalCode);

      if (!response.error) {
        postalCodeLoaded.value = true;

        state.street = response.street ?? state.street;
        state.district = response.district ?? state.district;
        state.city = response.city ?? state.city;
        state.state = response.state ?? state.state;
        $externalResults.postalCode = [];

        emit('update:postalCode', state);
      } else {
        $externalResults.postalCode = ['CEP não encontrado. Verifique se está correto'];
        v$.value.postalCode.$touch();
      }
    } catch(e) {
      postalCodeLoaded.value = true;
      emit('update:postalCode', state);

      throw e;
    } finally {
      postalCodeLoading.value = false;
    }
  }
}

const stateOptions = ref(
  [
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" }
  ]);

const validate = async () => {
  return await v$.value.$validate()
}

const getData = (): AddressInfo => {
  return state
}

onMounted(() => {
  if (props.data?.postalCode) {
    postalCodeLoaded.value = true;
  }
})

defineExpose({
  validate,
  getData
})
</script>
<style lang="sass" scoped>
.address-form
  position: relative
  margin: 0 auto
  text-align: left

  .form-line
    position: relative
    display: flex
    gap: 0 8px
    flex-wrap: wrap

    *
      width: 90px
      flex-shrink: 1
      flex-grow: 1

  .actions
    display: flex
    flex-direction: column
    margin: 32px 0
    align-items: center

    .loading
      height: 50px

    .large
      text-align: center
      font-size: 24px
      width: 220px

  .postal-code-loading
    position: absolute
    top: 16px
    right: 8px
    transform: scale(0.5)
</style>
