interface ViaCepResponse {
  logradouro?: string
  bairro?: string
  localidade?: string
  uf?: string
  erro?: boolean
}

export default {
  async findAddressByCep(cep: string): Promise<AddressResponse> {
    // @ts-ignore
    const json: ViaCepResponse = await $fetch(`https://viacep.com.br/ws/${cep}/json/`)

    return {
      street: json['logradouro'],
      district: json['bairro'],
      city: json['localidade'],
      state: json['uf'],
      error: json['erro'] === true
    }
  },
}

export interface AddressResponse {
  street?: string,
  district?: string,
  city?: string,
  state?: string,
  error?: boolean
}